import { __awaiter, __decorate, __extends, __generator } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import ScheduleMeeting from './Meetings/ScheduleMeeting.vue';
import { eventsListParams } from './Meetings/gapiHelpers';
var Meetings = (function (_super) {
    __extends(Meetings, _super);
    function Meetings() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.showModal = false;
        _this.type = 'month';
        _this.types = ['month', 'week', 'day', '4day'];
        _this.mode = 'stack';
        _this.modes = ['stack', 'column'];
        _this.weekday = [0, 1, 2, 3, 4, 5, 6];
        _this.weekdays = [
            { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
            { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
            { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
            { text: 'Mon, Wed, Fri', value: [1, 3, 5] }
        ];
        _this.value = '';
        _this.events = [];
        _this.colors = ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'];
        _this.gapiInterval = null;
        return _this;
    }
    Meetings.prototype.getEventColor = function () {
        return this.colors[Math.floor(Math.random() * this.colors.length)];
    };
    Meetings.prototype.getEvents = function () {
        console.log(this.events);
        return this.events;
    };
    Meetings.prototype.mounted = function () {
        !this.$gapi.isAuthenticated() && this.$gapi.login();
        this.isGapiLogin();
    };
    Meetings.prototype.isGapiLogin = function () {
        var _this = this;
        var _a = this, $gapi = _a.$gapi, loadCalendarEvents = _a.loadCalendarEvents;
        this.gapiInterval = setInterval(function () {
            if ($gapi.isAuthenticated()) {
                clearInterval(_this.gapiInterval);
                loadCalendarEvents();
            }
        }, 20);
    };
    Meetings.prototype.beforeDestroy = function () {
        this.gapiInterval && clearInterval(this.gapiInterval);
    };
    Meetings.prototype.loadCalendarEvents = function (newEventId) {
        if (newEventId === void 0) {
            newEventId = null;
        }
        return __awaiter(this, void 0, void 0, function () {
            var _a, $gapi, gapiInterval, client, result, items, getStartTime, newEventAdded;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this, $gapi = _a.$gapi, gapiInterval = _a.gapiInterval;
                        return [4, $gapi.getGapiClient()];
                    case 1:
                        client = (_b.sent()).client;
                        return [4, client.calendar.events.list(eventsListParams())];
                    case 2:
                        result = (_b.sent()).result;
                        items = result.items;
                        console.log('loadCalendar -> items', items);
                        getStartTime = function (date) { return new Date(date).toISOString().split('T')[1].slice(0, 5); };
                        if (!newEventId) {
                            items.map(function (item) {
                                return _this.events.push({
                                    name: getStartTime(item.start.dateTime) + " " + item.summary,
                                    start: new Date(item.start.dateTime),
                                    end: new Date(item.end.dateTime),
                                    color: 'cyan',
                                    timed: false
                                });
                            });
                        }
                        else {
                            newEventAdded = items.find(function (item) { return item.id === newEventId; });
                            this.events.push({
                                name: getStartTime(newEventAdded.start.dateTime) + " " + newEventAdded.summary,
                                start: new Date(newEventAdded.start.dateTime),
                                end: new Date(newEventAdded.end.dateTime),
                                color: 'indigo',
                                timed: false
                            });
                        }
                        return [2];
                }
            });
        });
    };
    Meetings = __decorate([
        Component({
            components: { ScheduleMeeting: ScheduleMeeting }
        })
    ], Meetings);
    return Meetings;
}(Vue));
export default Meetings;
