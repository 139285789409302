export var eventsListParams = function () {
    return ({
        calendarId: 'primary',
        timeMin: new Date().toISOString(),
        showDeleted: false,
        singleEvents: true,
        maxResults: 30,
        orderBy: 'startTime'
    });
};
