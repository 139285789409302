var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Overlay",
    { attrs: { show: _vm.show } },
    [
      _c(
        "VCard",
        { staticClass: "ScheduleMeeting" },
        [
          _c("h1", { staticClass: "text-h6" }, [_vm._v("Schedule a BIMMeet")]),
          _vm.error
            ? _c("b", { staticStyle: { color: "red", "font-size": "15px" } }, [
                _vm._v("You must complete all fields!")
              ])
            : _vm._e(),
          _c("VTextField", {
            attrs: { label: "Meet title", "prepend-icon": "mdi-format-title" },
            model: {
              value: _vm.title,
              callback: function($$v) {
                _vm.title = $$v
              },
              expression: "title"
            }
          }),
          _c(
            "VDialog",
            {
              ref: "dialog",
              attrs: {
                "return-value": _vm.date,
                persistent: "",
                width: "290px"
              },
              on: {
                "update:returnValue": function($event) {
                  _vm.date = $event
                },
                "update:return-value": function($event) {
                  _vm.date = $event
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "VTextField",
                        _vm._g(
                          _vm._b(
                            {
                              attrs: {
                                label: "Picker in dialog",
                                "prepend-icon": "mdi-calendar",
                                readonly: ""
                              },
                              model: {
                                value: _vm.date,
                                callback: function($$v) {
                                  _vm.date = $$v
                                },
                                expression: "date"
                              }
                            },
                            "VTextField",
                            attrs,
                            false
                          ),
                          on
                        )
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.modal,
                callback: function($$v) {
                  _vm.modal = $$v
                },
                expression: "modal"
              }
            },
            [
              _c(
                "VDatePicker",
                {
                  attrs: { scrollable: "" },
                  model: {
                    value: _vm.date,
                    callback: function($$v) {
                      _vm.date = $$v
                    },
                    expression: "date"
                  }
                },
                [
                  _c("VSpacer"),
                  _c(
                    "VBtn",
                    {
                      attrs: { text: "", color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.modal = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "VBtn",
                    {
                      attrs: { text: "", color: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.$refs.dialog.save(_vm.date)
                        }
                      }
                    },
                    [_vm._v("OK")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "VMenu",
            {
              ref: "menu",
              attrs: {
                "close-on-content-click": false,
                "nudge-right": 40,
                "return-value": _vm.time,
                transition: "scale-transition",
                "offset-y": "",
                "max-width": "290px",
                "min-width": "290px"
              },
              on: {
                "update:returnValue": function($event) {
                  _vm.time = $event
                },
                "update:return-value": function($event) {
                  _vm.time = $event
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "VTextField",
                        _vm._g(
                          _vm._b(
                            {
                              attrs: {
                                label: "Picker in menu",
                                "prepend-icon": "mdi-clock-time-four-outline",
                                readonly: ""
                              },
                              model: {
                                value: _vm.time,
                                callback: function($$v) {
                                  _vm.time = $$v
                                },
                                expression: "time"
                              }
                            },
                            "VTextField",
                            attrs,
                            false
                          ),
                          on
                        )
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.menu2,
                callback: function($$v) {
                  _vm.menu2 = $$v
                },
                expression: "menu2"
              }
            },
            [
              _vm.menu2
                ? _c("VTimePicker", {
                    staticClass: "Time-picker",
                    attrs: { "full-width": "" },
                    on: {
                      "click:minute": function($event) {
                        return _vm.$refs.menu.save(_vm.time)
                      }
                    },
                    model: {
                      value: _vm.time,
                      callback: function($$v) {
                        _vm.time = $$v
                      },
                      expression: "time"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c("VCombobox", {
            attrs: {
              items: _vm.items,
              chips: "",
              label: "Attendees",
              multiple: "",
              "prepend-icon": "mdi-email",
              "small-chips": ""
            },
            scopedSlots: _vm._u([
              {
                key: "selection",
                fn: function(ref) {
                  var attrs = ref.attrs
                  var item = ref.item
                  var select = ref.select
                  var selected = ref.selected
                  return [
                    _c(
                      "VChip",
                      _vm._b(
                        {
                          attrs: { "input-value": selected, close: "" },
                          on: {
                            click: select,
                            "click:close": function($event) {
                              return _vm.remove(item)
                            }
                          }
                        },
                        "VChip",
                        attrs,
                        false
                      ),
                      [_c("strong", [_vm._v(_vm._s(item))])]
                    )
                  ]
                }
              }
            ]),
            model: {
              value: _vm.attendees,
              callback: function($$v) {
                _vm.attendees = $$v
              },
              expression: "attendees"
            }
          }),
          _c(
            "div",
            { staticClass: "ScheduleMeeting-buttons" },
            [
              _c(
                "VBtn",
                {
                  on: {
                    click: function($event) {
                      return _vm.$emit("hide-modal")
                    }
                  }
                },
                [_vm._v("CANCEL")]
              ),
              _c(
                "VBtn",
                {
                  attrs: { color: "primary" },
                  on: { click: _vm.validateInputs }
                },
                [_vm._v("SUBMIT")]
              )
            ],
            1
          ),
          _c("LoadingMeetModal", { attrs: { creatingMeet: _vm.loading } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }