var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Meetings" },
    [
      _c("h3", { staticClass: "Tab-title" }, [_vm._v("BIMMeet Calendar")]),
      _c(
        "div",
        { staticClass: "Meetings-calendar" },
        [
          _c(
            "VSheet",
            { staticClass: "d-flex", attrs: { tile: "", height: "54" } },
            [
              _c(
                "VBtn",
                {
                  staticClass: "ma-2",
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$refs.calendar.prev()
                    }
                  }
                },
                [_c("VIcon", [_vm._v("mdi-chevron-left")])],
                1
              ),
              _c("VSelect", {
                staticClass: "ma-2",
                attrs: {
                  items: _vm.types,
                  dense: "",
                  outlined: "",
                  "hide-details": "",
                  label: "type"
                },
                model: {
                  value: _vm.type,
                  callback: function($$v) {
                    _vm.type = $$v
                  },
                  expression: "type"
                }
              }),
              _c("VSelect", {
                staticClass: "ma-2",
                attrs: {
                  items: _vm.modes,
                  dense: "",
                  outlined: "",
                  "hide-details": "",
                  label: "event-overlap-mode"
                },
                model: {
                  value: _vm.mode,
                  callback: function($$v) {
                    _vm.mode = $$v
                  },
                  expression: "mode"
                }
              }),
              _c("VSelect", {
                staticClass: "ma-2",
                attrs: {
                  items: _vm.weekdays,
                  dense: "",
                  outlined: "",
                  "hide-details": "",
                  label: "weekdays"
                },
                model: {
                  value: _vm.weekday,
                  callback: function($$v) {
                    _vm.weekday = $$v
                  },
                  expression: "weekday"
                }
              }),
              _c("VSpacer"),
              _c(
                "VBtn",
                {
                  staticClass: "ma-2",
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$refs.calendar.next()
                    }
                  }
                },
                [_c("VIcon", [_vm._v("mdi-chevron-right")])],
                1
              )
            ],
            1
          ),
          _c("VCalendar", {
            ref: "calendar",
            staticClass: "Meetings-calendar",
            attrs: {
              weekdays: _vm.weekday,
              type: _vm.type,
              events: _vm.events,
              "event-overlap-mode": _vm.mode,
              "event-overlap-threshold": 30,
              "event-color": _vm.getEventColor
            },
            on: { change: _vm.getEvents },
            model: {
              value: _vm.value,
              callback: function($$v) {
                _vm.value = $$v
              },
              expression: "value"
            }
          }),
          _c(
            "VBtn",
            {
              staticClass: "mx-2 Meetings-addbtn",
              attrs: { fab: "", dark: "", color: "primary", medium: "" },
              on: {
                click: function($event) {
                  _vm.showModal = true
                }
              }
            },
            [_c("VIcon", { attrs: { dark: "" } }, [_vm._v("mdi-plus")])],
            1
          )
        ],
        1
      ),
      _vm.showModal
        ? _c("ScheduleMeeting", {
            attrs: { show: _vm.showModal },
            on: {
              "hide-modal": function($event) {
                _vm.showModal = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }