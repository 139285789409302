import { __awaiter, __decorate, __extends, __generator, __spreadArrays } from "tslib";
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Overlay from '@/components/common/Overlay.vue';
import moment from 'moment';
import getCalendarBody from '@/components/UserProfile/gapiHelpers';
import LoadingMeetModal from '@/components/UserProfile/LoadingMeetModal.vue';
import toast, { Snack } from '@/helpers/toastHelpers';
import store from '@/store';
var ScheduleMeeting = (function (_super) {
    __extends(ScheduleMeeting, _super);
    function ScheduleMeeting() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.date = new Date().toISOString().substr(0, 10);
        _this.attendees = [_this.userEmail];
        _this.description = '';
        _this.title = '';
        _this.items = [];
        _this.modal = false;
        _this.menu = null;
        _this.menu2 = null;
        _this.loading = false;
        _this.error = false;
        _this.time = null;
        return _this;
    }
    ScheduleMeeting.prototype.validateInputs = function () {
        var _a = this, meetDate = _a.meetDate, title = _a.title;
        return !title || !meetDate ? (this.error = true) : this.createCalendarEvent();
    };
    ScheduleMeeting.prototype.createCalendarEvent = function () {
        return __awaiter(this, void 0, void 0, function () {
            var meetId, _a, meetDate, title, attendeesReq, description, client, event, eventBody, result, err_1;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.loading = true;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 7, 8, 9]);
                        return [4, this.$functions.httpsCallable('createMeeting')()];
                    case 2:
                        meetId = (_b.sent()).data;
                        return [4, this.$firestore.doc("meets/" + meetId).update({ guests: this.attendees })];
                    case 3:
                        _b.sent();
                        this.description = window.location.origin + ("/meet/" + meetId);
                        _a = this, meetDate = _a.meetDate, title = _a.title, attendeesReq = _a.attendeesReq, description = _a.description;
                        return [4, this.$gapi.getGapiClient()];
                    case 4:
                        client = (_b.sent()).client;
                        event = client.calendar.events;
                        eventBody = getCalendarBody(meetDate, title, description, attendeesReq);
                        console.log('createCalendarEvent -> eventBody', eventBody);
                        return [4, event.insert({
                                calendarId: 'primary',
                                resource: eventBody
                            })];
                    case 5:
                        result = (_b.sent()).result;
                        console.log('createCalendarEvent -> result', result);
                        Snack.meetCreated(result.htmlLink);
                        this.$emit('hide-modal');
                        return [4, this.$parent.loadCalendarEvents(result.id)];
                    case 6:
                        _b.sent();
                        return [3, 9];
                    case 7:
                        err_1 = _b.sent();
                        console.log('createCalendarEvent -> result', err_1);
                        toast.badMessage('create a meet, verify that your account is active');
                        return [3, 9];
                    case 8:
                        setTimeout(function () {
                            _this.loading = false;
                        }, 2000);
                        return [7];
                    case 9: return [2];
                }
            });
        });
    };
    ScheduleMeeting.prototype.remove = function (attendee) {
        this.attendees.splice(this.attendees.indexOf(attendee), 1);
        this.attendees = __spreadArrays(this.attendees);
    };
    Object.defineProperty(ScheduleMeeting.prototype, "attendeesReq", {
        get: function () {
            return this.attendees.map(function (email) { return ({ email: email }); });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ScheduleMeeting.prototype, "meetDate", {
        get: function () {
            return this.time ? moment(this.date + ' ' + this.time).format() : null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ScheduleMeeting.prototype, "userEmail", {
        get: function () {
            return store.state.user.email || '';
        },
        enumerable: false,
        configurable: true
    });
    ScheduleMeeting.prototype.onError = function () {
        var _this = this;
        setTimeout(function () { return (_this.error = false); }, 4000);
    };
    __decorate([
        Prop()
    ], ScheduleMeeting.prototype, "show", void 0);
    __decorate([
        Watch('error')
    ], ScheduleMeeting.prototype, "onError", null);
    ScheduleMeeting = __decorate([
        Component({
            components: { Overlay: Overlay, LoadingMeetModal: LoadingMeetModal }
        })
    ], ScheduleMeeting);
    return ScheduleMeeting;
}(Vue));
export default ScheduleMeeting;
